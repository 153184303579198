import AWS from 'aws-sdk'

let apiRoot = "https://pubkit.newgen.co/";
let pubSubRoot = "https://pubsub.newgen.co/"
if(window.location.host==="localhost:3000" || window.location.host==="https://uatnewgenmisc.web.app/")
{
    apiRoot = "https://pubkittest.newgen.co/";
    pubSubRoot = "https://pubsubtest.newgen.co/"
}


export const whoAmI = apiRoot + "submissions/freelancer/api/whoami";

//https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/s3-example-creating-buckets.html
const credentials = new AWS.CognitoIdentityCredentials({IdentityPoolId:"us-east-1:d0488ef8-af13-4ba0-9c1c-aebd3215c422"});
const S3_BUCKET ='newgen-ae-dev';
const REGION ='us-east-1';

AWS.config.update({
    region:REGION,
    credentials:credentials,
    correctClockSkew: true
}
);

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})

AWS.config.credentials.refresh();


const findEmailPrefix = (emailAddress) =>
{
    emailAddress = emailAddress.toLowerCase().trim();
    emailAddress = emailAddress.replaceAll(" ","");
    emailAddress = emailAddress.replace("mailto:", "");
    return emailAddress.split("@")[0];
}

export { apiRoot, pubSubRoot, myBucket, AWS, findEmailPrefix }