import React, { useState, useEffect } from 'react';

import logo from './logo.svg';
import './App.css';
import 'antd/dist/antd.css';
import  { whoAmI } from "./Config";
import axios from 'axios';
import * as qs from 'query-string';

import { apiRoot } from "./Config";

import { Button, Checkbox, Form, Input, message } from 'antd';
import store from 'store2';
import { useHistory, useParams } from 'react-router-dom'

//http://localhost:3000/autologin/miscellaneous?email=saran@newgen.co&token=d453efc981e21274e2d222f9c8a3c83f-f564c243d8cb7d18c04c02adf6459132
function Autologin() {
  const urlParams = qs.parse(window.location.search);
  const params = useParams();

  useEffect(() => {
    console.log(urlParams);
    urlParams["username"] = urlParams["email"];
    setTimeout(onFinish(urlParams), 5000);
    console.log(store.get("freelancer"));
  });

  const onFinish = (values) => {
    console.log('Success:', values);
    axios.post(`${apiRoot}submissions/authenticateparams`, urlParams)
    .then(res => {
      if(res.data.status !== 200)
      {
        message.info("Unable to login");
        return;
      }
      console.log(res.data);
      store("pubkit_auth", {"email": res.data["user"]["email"], "token": res.data["user"]["token"]});
      store("freelance_auth", {"email": res.data["user"]["email"], "token": res.data["user"]["token"]});
      store("freelance_user", res.data["user"]);
      var freelanceRole = [];
      for(var i=0; i<res.data["capabilities"].length; i++)
      {
        freelanceRole.push(res.data["capabilities"][i]["name"]);
      }
      store("freelance_role", freelanceRole);
      store("pubkit_role", freelanceRole);
      
      var redirectPage = "/links";
      if(urlParams["redirectPage"]!= null) redirectPage = `${urlParams["redirectPage"]}`;
      window.location.href = redirectPage;
    }).catch(function (error)
    {
      console.log(error);
    });
  };

  return (
    <div>
        Autologin is in progress
    </div>
  );
}

export default Autologin;