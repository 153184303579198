import logo from './logo.svg';
import './App.css';
import Login from "./Login";
import Register from "./Register";
import Freelancer from "./Freelancer";
import Insurance from "./Insurance";
import DomainMails from "./DomainMails";
import Manager from "./Manager";
import 'antd/dist/antd.css'; 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Application from './Application';
import Autologin from './Autologin';
import Links from './Links';
import AttendanceRegularization from "./AttendanceRegularization"
import GenerateEmpCard from './GenerateEmpCard';
import Reimbursements from './Reimbursements';
import Preferences from './Preferences';
function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login/:message" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify-email/:fuzzyid" element={<Register />} />
          <Route path="/freelancer" element={<Freelancer />} />
          <Route path="/freelancer/applications/status" element={<Application />} />
          <Route path="/freelancer/applications/status/:status" element={<Application />} />
          <Route path="/freelancer/:page" element={<Freelancer />} />
          <Route path="/manager" element={<Manager props={{"page":""}}/>} />
          <Route path="/manager/freelancers" element={<Manager props={{"page":"freelancers"}}/>} />
          <Route path="/manager/applications" element={<Manager props={{"page":"applications"}}/>} />
          <Route path="/manager/application/:fuzzyid" element={<Application props={{"page":"applications"}}/>} />
          <Route path="/manager/projects" element={<Manager props={{"page":"projects"}}/>} />
          <Route path="/admin" element={<Manager />} />
          <Route path="/hr/insurance/:page/:fuzzyid" element={<Insurance />} />
          <Route path="/hr/payroll/attendance" element={<AttendanceRegularization />} />
          <Route path="/hr/employee/idcardgenerate" element={<GenerateEmpCard />} />
          <Route path="/hr/employee/reimbursements" element={<Reimbursements />} />
          <Route path="/hr/emails/:page/" element={<DomainMails />} />
          <Route path="/hr/emails/:page/:unithead" element={<DomainMails />} />
          <Route path="/hr/emails/:page/:unithead" element={<DomainMails />} />
          <Route path="/autologin/miscellaneous" element={<Autologin />} />
          <Route path="/user/emails/:page/" element={<DomainMails />} />
          <Route path="/user/preferences" element={<Preferences />} />
          <Route path="/links" element={<Links />} />
      </Routes>
  </BrowserRouter>
  );
}

export default App;
