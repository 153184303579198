import Reimbursements from '../Reimbursements';
import './styles.css';

const ReimbursementsHeader =()=>{

    return(

        <div className='header' >
            <center>
            <div className='titel' >

Reimbursement

</div>
            </center>
       

        <div className='line' style={{paddingLeft:"20px"}}>

            

        </div>

        <div className='body'>

        </div>

       </div>

    )

}

export default ReimbursementsHeader;